<template>
    <div id="user-content">
        <div v-show="!setStatus">
            <UserMsg style="margin-top: 5px" :userMsg="setUserMsg" />
            <!-- 时长统计 Start-->
            <PartitionTitle title="时长统计" />
            <div class="msg-time-content">
                <div>
                    <i></i>
                    <span>累计时长</span>
                    <span v-if="setUserMsg.game_time_total">{{
                        setUserMsg.game_time_total.hours
                    }}</span
                    >小时
                    <span v-if="setUserMsg.game_time_total">{{
                        setUserMsg.game_time_total.minutes
                    }}</span
                    >分钟
                </div>
            </div>
             <!-- End -->
            <!-- 账号设置 Start-->
            <PartitionTitle title="账号设置" />
            <div class="msg-account-content">
                <div>
                    <i :class="[
                            'i-email',
                            !!setUserMsg.email ? 'i-success' : 'i-error',
                        ]"
                    ></i>
                    <div>
                        <p v-if="!!setUserMsg.email">{{ setUserMsg.email }}</p>
                        <p v-else>暂无认证邮箱</p>
                        <p>您可以使用邮箱来保障您的账号安全</p>
                    </div>
                    <button :class="{active: !!setUserMsg.email}" @click="dialogFormVisible = true">修改邮箱</button>
                </div>
                <div>
                    <i
                        :class="[
                            'i-user',
                            setUserMsg.id_verified ? 'i-success' : 'i-error',
                        ]"
                    ></i>
                    <div v-if="!!setUserMsg.card_num">
                        <p>{{ setUserMsg.card_num }}</p>
                        <p>已认证 可试玩游戏</p>
                    </div>
                    <div v-else>
                        <p>尚未实名认证</p>
                        <p>认证后可试玩游戏</p>
                    </div>
                    <button
                        :class="{active: !!setUserMsg.card_num}"
                        v-show="!setUserMsg.id_verified"
                        @click="$store.dispatch('showDialog', true)"
                    >
                        立即认证
                    </button>
                </div>
                <div>
                    <i
                        :class="['i-phone', setUserMsg.phone ? 'i-success' : 'i-error',]"
                    ></i>
                    <div>
                        <p v-if="!!setUserMsg.phone">{{ setUserMsg.phone }}</p>
                        <p v-else>暂未绑定手机</p>
                        <p>您可以享受手机相关的安全及提醒服务</p>
                    </div>
                    <button
                    :class="{active: !!setUserMsg.phone}"
                     @click="setStatus = true">
                        修改手机
                    </button>
                </div>
            </div>
        </div>

        <div v-show="setStatus" class="stepEdit">
            <div class="navTop" style="margin-top: 48px">
                <span>当前位置：</span>
                <span @click="parentEvent">个人资料</span> 
                <i class="el-icon-arrow-right"></i>
                <span>修改手机</span>
            </div>
            <el-steps :active="active" align-center finish-status="success">
                <el-step title="1 安全验证"></el-step>
                <el-step title="2 绑定新手机">2</el-step>
                <el-step title="3 完成">3</el-step>
            </el-steps>
            <el-form ref="form" label-width="80px" class="demo-dynamic">
                <div v-if="active == 0" class="stepEdit_one">
                    <el-row>
                        <el-form-item label="" class="stepEdit_title">
                            <span>为了您的账号安全，请先验证当前手机号</span>
                        </el-form-item>
                        <div class="stepEdit_default">
                            <span>为了您的账号安全，请先验证当前手机号</span>
                        </div>
                        <el-form-item label="手机号">
                            <el-input
                                v-model.trim="oldData.tel"
                                placeholder="请输入手机号"
                            ></el-input>
                            <span
                                v-show="titleHelp.oldMsg[0] != ''"
                                class="stepEdit_help"
                                >{{ titleHelp.oldMsg[0] }}</span
                            >
                        </el-form-item>
                        <el-form-item label="验证码">
                            <el-row>
                                <el-col :span="14" >
                                    <el-input
                                        v-model.trim="oldData.code"
                                        placeholder="请输入验证码"
                                    ></el-input>
                                    <span
                                        v-show="titleHelp.oldMsg[1] != ''"
                                        class="stepEdit_help"
                                        >{{ titleHelp.oldMsg[1] }}</span
                                    >
                                </el-col>
                                <el-col :span="10" class="stepEdit_code">
                                    <el-button
                                        @click="getCode(false)"
                                        :disabled="buttonType[0]"
                                        :class="{ active: buttonType[0] }"
                                        >{{ showGetCode }}</el-button
                                    >
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-row>
                </div>
                <div v-else-if="active == 1" class="stepEdit_two">
                    <el-row>
                        <el-form-item label="" class="stepEdit_title">
                            <span>请设置新的手机</span>
                        </el-form-item>
                        <div class="stepEdit_default">
                            <span>请设置新的手机</span>
                        </div>

                        <el-form-item label="手机号">
                            <el-input
                                v-model.trim="newData.tel"
                                placeholder="请输入手机号"
                            ></el-input>
                            <span
                                v-show="titleHelp.newMsg[0] != ''"
                                class="stepEdit_help"
                                >{{ titleHelp.newMsg[0] }}</span
                            >
                        </el-form-item>
                        <el-form-item label="验证码">
                            <el-row>
                                <el-col :span="14" >
                                    <el-input
                                        v-model.trim="newData.code"
                                        placeholder="请输入验证码"
                                    ></el-input>
                                    <span
                                        v-show="titleHelp.newMsg[1] != ''"
                                        class="stepEdit_help"
                                        >{{ titleHelp.newMsg[1] }}</span
                                    >
                                </el-col>
                                <el-col :span="10" class="stepEdit_code">
                                    <el-button
                                        @click="getCode(true)"
                                        :disabled="buttonType[1]"
                                        :class="{ active: buttonType[1] }"
                                        >{{ showGetCode }}</el-button
                                    >
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-row>
                </div>
                <div v-else-if="active == 3" class="stepEidt_three">
                    <div v-if="stepOverStatus">
                        <div class="stepSuccess_img">
                            <img src="@/assets/images/user/i-step-success.png" alt="">
                        </div>
                        <p>更换号码成功</p>
                        <p>以后可用该手机号登录了哦</p>
                        <el-button @click="stepOver">我知道了</el-button>
                    </div>
                    <div v-else>
                        <div class="stepSuccess_img">
                            <img src="@/assets/images/user/i-step-error.png" alt="">
                        </div>
                        <p>更换号码失败</p>
                        <p>抱歉,更换号码失败，请稍后再试</p>
                        <el-button @click="stepOver">我知道了</el-button>
                    </div>
                </div>
                <div class="stepEdit_next" v-if="active != 3">
                    <el-form-item label="">
                        <el-button @click="nextStep">下一步</el-button>
                    </el-form-item>
                </div>
            </el-form>
        </div>
        <!-- End -->
        <!-- 修改邮箱弹框 Start-->
        <el-dialog
            title="邮箱修改"
            id="user-dialog"
            :visible.sync="dialogFormVisible"
            width="500px"
        >
            <img
                src="@/assets/images/officialWebsite/icon-close.png"
                class="close-img"
                @click="dialogFormVisible = !dialogFormVisible"
            />
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
                <el-form-item prop="email">
                    <el-input
                        v-model.trim="ruleForm.email"
                        placeholder="请输入邮箱"
                    ></el-input>
                </el-form-item>
                <el-button
                    class="el-button--warning"
                    type="primary"
                    @click="submitForm('ruleForm')"
                    >提交
                </el-button>
            </el-form>
        </el-dialog>
        <!-- End -->
    </div>
</template>
<script>
import UserMsg from "@/components/officialWebsite/UserMsg";
import PartitionTitle from "@/components/officialWebsite/PartitionTitle";

export default {
    name: "UserCenter",
    props: {
        userMsgStatus: {
            //设置点击后加载请求
            type: Boolean,
            default: false,
        },
    },
    data() {
        // 邮箱验证
        var email = (rule, value, callback) => {
            const r = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;

            if (value == null || String(value).trim() === "") {
                return callback(new Error("不能为空"));
            } else if (!r.test(value)) {
                return callback(new Error("请输入正确的邮箱"));
            } else {
                return callback();
            }
        };

        return {
            userData: {                 //所有数据
                avatar: "",             //用户头像
                name: "",               //用户名
                age: 0,                 //用户年龄
                sex: "未认证",          //性别
                email: "",              //邮箱
                id_verified: "",
                card_num: "", 
                hours: 0,
                minutes: 0,
            }, 
            ruleForm: { email: "" },        //邮箱值
            formLabelWidth: "100px",        //label宽度
            dialogFormVisible: false,       //弹框的隐藏显示
            userNameVal: "Jessie Tang",     //用户值
            dialogTableVisible: false,
            rules: {
                //邮箱字段验证
                email: [{ require: true, validator: email, trigger: "blur" }],
            },
            setStatus: false,        //开启手机号修改
            active: 0,
            oldData: {
                //旧手机号对象
                tel: "",
                key: "",
                code: "",
            },
            newData: {
                //新手机号对象
                tel: "",
                key: "",
                code: "",
            },
            titleHelp: {
                //号码的格式验证帮助提示
                oldMsg: ["", ""],
                newMsg: ["", ""],
            },
            telText: {
                //号码格式提示文字
                valNull: "手机号码不能为空 ！",
                valNub: "手机号码只支持数字 ！",
                valCustom: "请输入正确的手机号 ！",
            },
            codeText: {
                //验证码格式提示文字
                valNull: "验证码不能为空 ！",
                valNub: "验证码只支持数字 ！",
                valCustom: "请输入正确的验证码 ！",
            },
            counttime: 60,
            codeinterval: "",                           //定时器
            dataTel: ["", ""],                          //0:旧号码验证过后存储，1：新号码验证过后存储
            showGetCode: "获取验证码",
            buttonType: [false, false],                 //验证码按钮的控制
            stepOverStatus: 1                           //1成功 0失败
        };
    },
    mounted() {
        this.userData = this.$store.state.login.user;
        if (this.userMsgStatus) {
            this.$API
                .getUserInfo({ need_gaming_time: true })
                .then((response) => {
                    let res = response.data;
                    if (res.status) {
                        this.$store.dispatch("login", res.data);
                    }
                });
        }
    },
    computed: {
        setUserMsg() {
            return this.$store.state.login.user || this.userData;
        },
    },
    components: {
        UserMsg,
        PartitionTitle,
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 验证通过
                    this.submitNameEmail({ email: this.ruleForm.email });
                    this.$store.commit("UPDATE_USER", this.userData);
                    this.$store.commit("MESSAGE_TYPE", true);
                    this.$store.commit("MESSAGE_TEXT", "邮箱修改成功");
                    this.ruleForm.email = "";
                } else {
                    // 验证有误
                    return false;
                }
            });
        },
        submitNameEmail(data) {
            // 用户名与邮箱的提交与修改
            this.$API.submitUserCenterData(data).then((response) => {
                let res = response.data;
                if (res.status) {
                    this.userData.email = res.data.email;
                    this.dialogFormVisible = false;
                }
            });
        },
        getCode(status) {
            /**
             * 获取验证码
             * status: false 代表现手机验证码获取   true 代表新号码
             */
            let obj = status ? this.newData : this.oldData,
                typeTel = this.numberCheck(obj.tel, "tel", this.telText); //手机号格式验证
            if (!typeTel) {
                // 手机格式通过，发送验证码请求
                this.$set(this.buttonType, Number(status), true);

                this.$API
                    .sendVerifyCode({ phone: obj.tel })
                    .then((response) => {
                        this.errMsg = "";
                        let res = response.data;
                        if (res.status == 1) {
                            obj.key = res.data.key;
                            // 存储验证后数据
                            this.dataTel[status ? 1 : 0] = obj.tel;
                            this.showGetCode = this.counttime + "s后重新发送";
                            // 触发定时读秒
                            this.setCodeTime();
                        } else {
                            this.$set(this.buttonType, Number(status), false);
                        }
                    });
                typeTel = ["", ""];
            } else {
                // 异常,提示
                typeTel = [typeTel, ""];
            }

            this.$set(this.titleHelp, status ? "newMsg" : "oldMsg", typeTel);
        },
        nextStep() {
            /**
             * 下一步按钮的提交控制;
             */

            if (this.active == 0 || this.active == 1) {
                let helpStatus = this.active ? "newMsg" : "oldMsg";                 //提示信息区分
                let obj = this.active ? this.newData : this.oldData;                //新旧数据区分
                let typeTel = this.numberCheck(obj.tel, "tel", this.telText);       //手机验证后结果
                let typeCode = this.numberCheck(obj.code, "code", this.codeText );  //验证码验证后结果

                // 先验证手机格式设置提示
                if (typeTel) {
                    this.$set(this.titleHelp, helpStatus, [typeTel, ""]);
                    return;
                }
                
                // 电话号码时候获取验证码后又变更与未获取验证码
                if (obj.tel != this.dataTel[this.active]) {
                    if(this.counttime < 60){
                        //复原定时
                        this.infoCodeTime();
                    }
                    this.$set(this.titleHelp, helpStatus, ["", "手机号变更，请重新获取验证码 ！",]);
                    return;
                }
                if( !!obj.key ){
                    this.$set(this.titleHelp, helpStatus, ["",  "请获取验证码 ！"]);
                }
                // 验证码比较设置提示
                if (typeCode) {
                    this.$set(this.titleHelp, helpStatus, ["", typeCode]);
                    return;
                }
                // 发送验证请求，成功进行下一步
                this.$API
                    .changeOrVerifyPhone({
                        phone: obj.tel,
                        verification_key: obj.key,
                        verification_code: obj.code,
                        is_update: this.active,
                    })
                    .then((response) => {
                        let res = response.data;
                            obj.tel = "";
                            obj.key = "";
                            obj.code = "";
                            this.infoCodeTime();
                        if (res.status) this.active++;
                        if (this.active == 2) {
                            this.active = 3;
                            this.stepOverStatus = res.status;
                            
                            if(res.status == 1 ){
                                let nObj = this.$store.state.login.user;
                                nObj.phone = res.data.phone;
                                // 成功清空当前各类数据,初始化，进入下一步
                                this.$store.commit("UPDATE_USER", nObj);
                            }
                        } 
                    });
                this.$set(this.titleHelp, helpStatus, ["", ""]);
            } else {
                this.active = 0;
            }
        },
        stepOver(){
            // 完成按钮
            this.setStatus = false;
            this.active = 0;
        },
        numberCheck(value, type, text) {
            // 验证码，与手机号的空值验证
            if (!value) {
                //判空
                return text.valNull;
            }

            if (!Number.isInteger(Number(value))) {
                //只允许数字
                return text.valNub;
            } else {
                if (type == "code") {
                    let codeLength = value.length;
                    if (codeLength != 6 && codeLength != 4) {
                        return text.valCustom;
                    }
                } else {
                    let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
                    if (!reg_tel.test(value)) {
                        return text.valCustom;
                    }
                }
                
                // 全部通过，返回false；
                return false;
            }
        },
        setCodeTime() {
            // 验证码倒计时设置
            this.codeinterval = setInterval(() => {
                this.counttime--;
                this.showGetCode = this.counttime + "s后重新发送";
                if (this.counttime == -1) {
                    this.infoCodeTime();
                    clearInterval(this.codeinterval);
                }
            }, 1000);
        },
        infoCodeTime(){
            if(this.codeinterval) clearInterval(this.codeinterval);
            this.counttime = 60;
            this.showGetCode = "获取验证码";
            this.buttonType = [false,false];
        },
        parentEvent(){
            this.setStatus = false;
            this.active = 0;
            this.infoCodeTime();
            this.oldData = {
                //旧手机号对象
                tel: '',
                key: '',
                code: ''
            };
            this.newData = {
                //新手机号对象
                tel: '',
                key: '',
                code: ''
            }
        }
    },
};
</script>



<style lang="scss" scoped>
// 全局样式
#user-content {
    // 苹方-简 常规体
    font-weight: 400;
    font-family: "PingFangSC-Regular", sans-serif;
    padding: 0 40px;
}

// 时长统计
.msg-time-content {
    height: 60px;
    margin-top: 30px;
    > div {
        width: 50%;
        height: 24px;
        font-weight: 500;
        color: #2f333d;
        display: inline-block;
        i {
            float: left;
            margin-top: 5px;
            margin-right: 8px;
            background-image: url("../../../assets/images/user/i-time.png");
        }
        span {
            font-size: 16px;
            font-weight: 600;
            color: #d5a561;
        }
        :nth-child(2) {
            // font-size: 14px;
            font-weight: 400;
            margin-right: 16px;
            color: #606062;
        }
    }
}

// 账号设置
.msg-account-content {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    >:nth-child(1)::after,
    >:nth-child(2)::after{
        content: '';
        width: 92%;
        height: 1px;
        position: absolute;
        left: 0px;
        bottom: 0px;
        background: #ececec;
    }
    > div {
        width: 50%;
        margin-top: 10px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        position: relative;
        display: flex;
        i {
            margin-top: 8px;
            left: 0;
        }
        // 邮箱与认证账号,提示信息的显示;
        > div {
            width: 60%;
            p {
                margin-bottom: 0;
                white-space: nowrap;
            }
            :nth-child(1) {
                height: 25px;
                font-weight: 500;
                color: #000000;
            }
            :nth-child(2) {
                height: 17px;
                font-size: 12px;
                color: #888888;
            }
        }
 
        button {
            width: 75px;
            height: 32px;
            font-size: 14px;
            margin-top: 8px;
            border-radius: 5px;
            border: none;
            outline: none;
            cursor: pointer;
            color: #e3af6d;
            border: 1px solid #e3af6d;
            background: rgba($color: #000000, $alpha: 0);
        }
        button.active{
            color: #888888;
            border: 1px solid #adadb0;
        }
        button:hover {
            color: #fff;
            cursor: pointer;
            border: 1px solid #e3af6d;
            background: #e3af6d;
        }
    }
}

// 手机号修改步骤条
.stepEdit {
    .feedbackTitle{
        position: relative;
        text-align: center;
        margin-top: 15px;
        padding-bottom: 16px;
        border-bottom: 1px solid #D2BDA0;
        >span{
            font-size: 18px;
            color: #D5A561;
            font-weight: bold;
        }
        .answerFeedbackBtn{
            width: 70px;
            height: 28px;
            font-size: 12px;
            border-radius: 4px;
            position: absolute;
            left:0;
            border: none;
            outline: none;
            color: #ffffff;
            background: linear-gradient(154deg,#fad8a1 0%,#e3af6d 100%);
        }
        .answerFeedbackBtn:hover{
            background: linear-gradient(154deg,#fdd189 0%,#e2a556 100%);
        }
        .blackLeft{
            position: absolute;
            left: 0;
            margin-top: 3px;
            >svg{
                margin-top: -2px;
            }
            >span{
                color: #606062;
            }
        }
        .blackLeft:hover{
            cursor: pointer;
            >svg .blackLeft_svg{
                fill: #828283cb;
            }
            >span{
                color: #828283cb;
            }
        }
    }
    .navTop{
        font-size: 14px;
        color: #555555;
        :nth-child(1){
            color: #b4b4b4;
        }
        :nth-child(2):hover{
            cursor: pointer;
            color: #e3af6d;
            text-decoration: line;
        }
    }
    /deep/ .el-steps {
        margin-top: 30px;
        .el-step__head.is-success{
            border-color: #D5A561;
        }
        .el-step__head.is-success,
        .el-step__title.is-success{
            color: #D5A561;
            font-weight: bold;
        }
    }
    .stepEdit_one,
    .stepEdit_two,
    .stepEdit_three {
        width: 400px;
        margin: 0 auto;
        padding-top: 20px;
        .el-form-item {
            position: relative;
            .stepEdit_help {
                position: absolute;
                left: 15px;
                bottom: -25px;
                font-size: 12px;
                color: crimson;
            }
        }
        .stepEdit_title{
            margin-bottom: 5px;
            span{
                font-weight: bold;
                font-size: 16px;
            }
        }
        .stepEdit_default{
            display: none;
            text-align: center;
            margin-bottom: 20px;
            span{
                font-weight: bold;
                font-size: 16px;
            }
        }
    }
    .stepEidt_three {
        text-align: center;
        .stepSuccess_img{
            width: 250px;
            margin:0 auto;
            padding-top: 40px;
            img{
                width: 100%;
            }

        }
        :nth-child(2){
            margin-top: 10px;
            font-weight: bold;
            font-size: 20px;
        }
        :nth-child(3){
            color: #D5A561;
        }
        .el-button{
            width: 150px;
            height: 40px;
            margin-bottom: 20px;
            border: none;
            outline: none;
            font-size: 16px;
            border-radius: 20px;
            letter-spacing: 1px;
            color: #fff;
            cursor: pointer;
            background: linear-gradient(154deg, #fad8a1 0%, #e3af6d 100%);
        }
        .el-button:hover{
            background: #e3af6d;
        }
    }

    // 验证码按钮
    .stepEdit_code {
        padding-left: 5px;
        .el-button{
            width: 100%;
            border: none;
            outline: none;
            color: #fff;
            cursor: pointer;
            background: linear-gradient(154deg, #fad8a1 0%, #e3af6d 100%);
        }
        .el-button:hover{
            background: #e3af6d;
        }
        .el-button.active{
            color: #ADADB0 !important;
            background: #F0EFEE !important;
            border: 1px solid #ADADB0 !important;
        }
    }

    // 下一步按钮
    .stepEdit_next {
        width: 400px;
        margin: 0 auto;
        button {
            width: 100% !important;
            border: none;        
            outline: none;
            cursor: pointer;
            color: #fff;
            background: linear-gradient(154deg, #fad8a1 0%, #e3af6d 100%);

        }
        button:hover{
            background: #e3af6d;
        }
    }
}

/deep/ .el-input__inner:focus {
    border-color: #D69D4D;
    box-shadow: none;
}

// 弹框样式
/deep/ #user-dialog {
    .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
        border-color: #d69d4d;
        outline: none;
    }
    .el-input__inner {
        height: 56px;
        font-size: 16px;
    }
    .el-dialog__headerbtn {
        outline: none;
        i:hover {
            color: #d69d4d;
        }
    }
    .el-button--default {
        color: #d69d4d;
        border-color: #d69d4d;
    }
}

i {
    width: 16px;
    height: 16px;
    display: inline-block;
}

/deep/ .el-dialog__header {
    text-align: center;
}

.el-button--warning {
    width: 100%;
    height: 56px;
    margin-top: 20px;
}
/deep/ .el-dialog__body {
    padding: 25px 75px 30px;
}

.close-img {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.title-p {
    font-size: 16px;
    font-weight: 500;
    color: #888888;
}

.i-email,
.i-phone,
.i-user {
    width: 32px;
    height: 32px;
    margin-right: 16px;
    background-size: cover !important;
}

.i-email.i-success {
    background-image: url("../../../assets/images/user/i-email-success.png");
}
.i-email.i-error {
    background-image: url("../../../assets/images/user/i-email-error.png");
}
.i-user.i-success {
    background-image: url("../../../assets/images/user/i-user-success.png");
}
.i-user.i-error {
    background-image: url("../../../assets/images/user/i-user-error.png");
}
.i-phone.i-success {
    background-image: url("../../../assets/images/user/i-phone-success.png");
}
.i-phone.i-error {
    background-image: url("../../../assets/images/user/i-phone-error.png");
}

@media screen and (max-width: 1000px) and (min-width: 700px) {
    .msg-account-content > div {
        width: 100%;
        > div {
            width: 70%;
        }
    }
}

@media screen and (max-width: 700px) and (min-width: 500px) {
    #user-content {
        padding: 0 16px;
    }
    .msg-time-content {
        height: 50px;
    }
    .msg-account-content {
        > div {
            width: 100%;
            > div {
                width: 70%;
            }
        }
    }
}
@media screen and (max-width: 500px) and (min-width: 391px) {
    #user-content {
        padding: 0 16px;
    }
    .msg-time-content {
        height: 50px;
        font-size: 14px;
        > div {
            width: 100%;
            span {
                font-size: 12px;
            }
        }
    }

    .msg-account-content {
        > div {
            width: 100%;
            > div {
                width: 64%;
                p:nth-child(1) {
                    height: 20px;
                    font-size: 14px;
                }
                p:nth-child(2) {
                    transform: scale(0.75);
                    transform-origin: left;
                }
            }
            > i {
                margin-top: 5px;
            }
            button {
                width: 65px;
                margin-top: 5px;
                font-size: 12px;
            }
        }
    }
    /deep/ .el-dialog {
        width: 100% !important;
    }
    /deep/ .el-dialog__body {
        padding: 25px 45px 30px;
    }

    .stepEdit{
        .stepEdit_next,
        .stepEdit_one,
        .stepEdit_two,
        .stepEdit_three {
            width: 100%;
            .stepEdit_title{
                display: none;
            }
            .stepEdit_default{
                display: block;
            }
            /deep/.el-form-item{
                .el-form-item__label{
                    display: none;
                }
                .el-form-item__content{
                    margin-left: 0px !important;
                }
            }
        }

    }
}

@media screen and (max-width: 390px) {
    #user-content {
        padding: 0 16px;
    }
    .msg-time-content {
        height: 50px;
        font-size: 14px;
        > div {
            width: 100%;
            span {
                font-size: 12px;
            }
            i {
                margin-top: 5px;
            }
        }
    }

    .msg-account-content {
        > div {
            width: 100%;
            > div {
                width: 60%;
                p:nth-child(1) {
                    height: 20px;
                    font-size: 14px;
                }
                p:nth-child(2) {
                    transform: scale(0.75);
                    transform-origin: left;
                }
            }
            > i {
                margin-top: 5px;
            }
            button {
                width: 65px;
                margin-top: 5px;
                font-size: 12px;
            }
        }
    }
    /deep/ .el-dialog {
        width: 100% !important;
    }
    /deep/ .el-dialog__body {
        padding: 25px 45px 30px;
    }
    
    .stepEdit{
        .stepEdit_next,
        .stepEdit_one,
        .stepEdit_two,
        .stepEdit_three {
            width: 100%;
            .stepEdit_title{
                display: none;
            }
            .stepEdit_default{
                display: block;
            }
            /deep/.el-form-item{
                .el-form-item__label{
                    display: none;
                }
                .el-form-item__content{
                    margin-left: 0px !important;
                }
            }
        }
    }
}
</style>